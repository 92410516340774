import '@css/main.scss';
import '@js/bootstrap';

import {Header} from '@js/components/Header';
import {Slider} from '@js/components/Slider';
import {Accordion} from "@js/components/Accordion";
import {Animation} from "@js/utils/Animation";
import {Map} from "@js/components/Map";
import {Video} from "@js/components/Video";

window.onload = () => {

    new Header()
    new Slider()
    new Accordion()
    new Animation()
    new Map()
    new Video()


    if (window.location.hash) {
        const hash = window.location.hash.substring(1)

        if (hash) {
            var targetElement = document.getElementById(hash);
            var offsetTop = targetElement.offsetTop;

            targetElement.classList.add('open')

            window.scrollTo({
                top: offsetTop,
                behavior: 'smooth'
            });
        }
    }

}
