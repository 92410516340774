export class Map {

  constructor() {
    this.init();
  }

  init() {
    this.pins = document.querySelectorAll('.map-site')
    this.activities = document.querySelectorAll('.c-activity')
    if (this.pins.length > 0) {
      this.registerEvents()
      const idSite = "25"
      this.update(idSite)
    }


  }

  registerEvents() {
    this.pins.forEach(pin => {
      pin.addEventListener('click', (e) => {
        this.reset()
        const idSite = e.target.getAttribute('data-site')
        const name = e.target.getAttribute('data-name')
        const marker = document.getElementById(idSite)
        marker.style.stroke = '#FF5700'

        this.update(idSite)

        document.getElementById('site').textContent = name

        //scroll to the div with id "activities"
        const activities = document.getElementById('activities')
        activities.scrollIntoView({behavior: 'smooth'})

      })
    })

  }

  update(idSite) {
    this.activities.forEach(activity => {
      if (activity.getAttribute('data-sites').includes(idSite)) {
        activity.classList.remove('--blocked')
      } else {
        activity.classList.add('--blocked')
      }
    })
  }

  reset() {
    this.pins.forEach(pin => {
      const idSite = pin.getAttribute('data-site')
      const marker = document.getElementById(idSite)
      marker.style.stroke = '#fff'
    })
  }

}
